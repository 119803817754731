<template>
  <div class="chart">
    <div id="costCompareBox"></div>
  </div>
</template>
<script>
export default {
  props: {
    costCompareData: {},
  },
  data() {
    return {
      myChart:{},
      typeData:[],
      bzData:[],
      sbData:[],
      czData:[],
    };
  },
  watch:{
    costCompareData:{
      handler: function(){
          this.renderEcharts();
      },
      deep: true
    }
  },
  created(){
    setTimeout(() => {
      this.renderEcharts();
    }, 500);
    
  },
  
  methods: {
    renderEcharts(){
      for(var i=0;i<this.costCompareData.length;i++){
        this.typeData.push(this.costCompareData[i].generaName);
        this.bzData.push(this.costCompareData[i].standardValue||0);
        this.sbData.push(this.costCompareData[i].declareValue||0);
        this.czData.push(this.costCompareData[i].maxValue||0);

      }
      this.myChart = this.$echarts.init(document.getElementById('costCompareBox'));
      let option;
      option = {
          tooltip: {
              trigger: 'axis',
              axisPointer: {            // 坐标轴指示器，坐标轴触发有效
                  type: 'shadow'        // 默认为直线，可选为：'line' | 'shadow'
              }
          },
          legend: {
              data: ['标准阈值', '申报成本', '申报成本与标准阈值的差']
          },
          grid: {
              left: '3%',
              right: '4%',
              bottom: '3%',
              containLabel: true
          },
          xAxis: {
              type: 'value'
          },
          yAxis: {
              type: 'category',
              data: this.typeData
          },
          series: [
              {
                  name: '标准阈值',
                  type: 'bar',
                  stack: '总量',
               
                  data: this.bzData
              },
              {
                  name: '申报成本',
                  type: 'bar',
                  stack: '总量',
                
                  data: this.sbData
              },
              {
                  name: '申报成本与标准阈值的差',
                  type: 'bar',
                  stack: '总量',
                
                  data: this.czData
              },
             
          ]
      };


      this.myChart.setOption(option,true);
    },

    
  },
};
</script>
<style lang="less" scoped>
.chart{
  width: 381px;
 
}
#costCompareBox{
  width: 381px;
  height: 230px;
}
</style>
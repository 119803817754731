<template>
  <div class="chart">
    <div class="caseReasonBox">
      <div>风险类型数量统计</div>
      <div id="caseReasonBox"></div>
    </div>
    <div class="zhuBox">
      <div>风险案由类型数量统计</div>
      <div id="zhuBox"></div>

    </div>
  </div>
</template>
<script>
import {getStore,setStore} from "@/util/store";
export default {
  props: {
    caseReasonData: {},
  },
  data() {
    return {
      myChart:{},
      zhuChart:{},
      countData:[],
      typeData:[],
    };
  },
  watch:{
    caseReasonData:{
      handler: function(){
          this.renderEcharts();
      },
      deep: true
    }
  },
  created(){
    this.getCaseReason(4);
    
    

    setTimeout(() => {
      this.renderEcharts();
    }, 500);
    
  },
  
  methods: {
    eConsole(param) {
      this.zhuChart.dispose();
      document.getElementById('zhuBox').innerHTML = "";
      if(param.data.name=='本项目与施工方纠纷'){
        this.getCaseReason(2);
      }else if(param.data.name=='本项目建设方法律纠纷'){
        this.getCaseReason(1);
      }else if(param.data.name=='建设方其他法律纠纷'){
        this.getCaseReason(3);
      }else if(param.data.name=='施工方其他法律纠纷'){
        this.getCaseReason(4);
      }
    　　
    },
    //渲染环图
    renderEcharts(){
      this.myChart = this.$echarts.init(document.getElementById('caseReasonBox'));
      let option;
      var allAmount = this.caseReasonData.newsRiskCount+this.caseReasonData.companyRiskCount+this.caseReasonData.constructionRiskCount+this.caseReasonData.otherCompanyRiskCount+this.caseReasonData.otherConstructionRiskCount;
      option = {
          title: {
              text:'风险总数量',//主标题文本
              subtext:allAmount,//副标题文本
              left:'130px',
              top:'40%',
              textAlign:'center',
              textStyle:{
                fontSize: 14,
                color:'#999999',
              },
              subtextStyle:{
                  fontFamily : "微软雅黑",
                  fontSize: 16,
                  color:'#555555',
                  fontWeight: '600',
              }
          },
          tooltip: {
              trigger: 'item',
              formatter: '{a} <br/>{b}: {c} ({d}%)'
          },
          legend: {
              orient: 'vertical',
              icon: "circle",
              right: 20,
              bottom:20,
              data: ['舆情风险', '本项目建设方法律纠纷', '本项目与施工方纠纷', '建设方其他法律纠纷', '施工方其他法律纠纷']
          },
          series: [
              {
                  name: '风险类型数量统计',
                  type: 'pie',
                  radius: ['55%', '90%'],
                  avoidLabelOverlap: false,
                  center: ['135px', '50%'],
                  label: {
                    normal:{
                        show:true,
                        position:'inner', //标签的位置
                        textStyle : {
                          color:'#fff',
                          fontWeight : 300 ,
                          fontSize : 12    //文字的字体大小
                        },
                        formatter:'{c}'
                    }
                  },
               
                  labelLine: {
                      show: false
                  },
                  data: [
                      {value: this.caseReasonData.newsRiskCount, name: '舆情风险'},
                      {value: this.caseReasonData.companyRiskCount, name: '本项目建设方法律纠纷'},
                      {value: this.caseReasonData.constructionRiskCount, name: '本项目与施工方纠纷'},
                      {value: this.caseReasonData.otherCompanyRiskCount, name: '建设方其他法律纠纷'},
                      {value: this.caseReasonData.otherConstructionRiskCount, name: '施工方其他法律纠纷'}
                  ]
              }
          ]
      };


      this.myChart.setOption(option,true);
      setTimeout(() => {
        this.myChart.on("click", this.eConsole);
      }, 1000);

    },
    //渲染柱状图
    renderZhuEcharts(){
      this.zhuChart = this.$echarts.init(document.getElementById('zhuBox'));
      let option;
      option = {
          color: ['#3398DB'],
          tooltip: {
              trigger: 'axis',
              axisPointer: {            // 坐标轴指示器，坐标轴触发有效
                  type: 'shadow'        // 默认为直线，可选为：'line' | 'shadow'
              }
          },
          grid: {
              left: '3%',
              right: '4%',
              bottom: '3%',
              containLabel: true
          },
          xAxis: [
              {
                  type: 'category',
                  data: this.typeData,
                  axisTick: {
                      alignWithLabel: true
                  },
                  axisLabel: {
                      interval: 0,
                      formatter : function(params){
                        var newParamsName = "";// 最终拼接成的字符串
                        var paramsNameNumber = params.length;// 实际标签的个数
                        var provideNumber = 4;// 每行能显示的字的个数
                        var rowNumber = Math.ceil(paramsNameNumber / provideNumber);// 换行的话，需要显示几行，向上取整
                        /**
                         * 判断标签的个数是否大于规定的个数， 如果大于，则进行换行处理 如果不大于，即等于或小于，就返回原标签
                         */
                        // 条件等同于rowNumber>1
                        if (paramsNameNumber > provideNumber) {
                            /** 循环每一行,p表示行 */
                            for (var p = 0; p < rowNumber; p++) {
                                var tempStr = "";// 表示每一次截取的字符串
                                var start = p * provideNumber;// 开始截取的位置
                                var end = start + provideNumber;// 结束截取的位置
                                // 此处特殊处理最后一行的索引值
                                if (p == rowNumber - 1) {
                                    // 最后一次不换行
                                    tempStr = params.substring(start, paramsNameNumber);
                                } else {
                                    // 每一次拼接字符串并换行
                                    tempStr = params.substring(start, end) + "\n";
                                }
                                newParamsName += tempStr;// 最终拼成的字符串
                            }

                        } else {
                            // 将旧标签的值赋给新标签
                            newParamsName = params;
                        }
                        //将最终的字符串返回
                        return newParamsName
                      },
                      // rotate:50
                  }
              }
          ],
          yAxis: [
              {
                  type: 'value',
                  axisLabel: {
                    formatter: '{value}'
                  }
              }
          ],
          series: [
              {
                  name: '数量',
                  type: 'bar',
                  barWidth: '60%',
                  data: this.countData,
                  label: {
                    normal:{
                        show:true,
                        position:'top', //标签的位置
                        formatter:'{c}'
                    }
                  },
              }
          ]
      };
      this.zhuChart.setOption(option,true);
    },
    
     
    //获取案由数据
    getCaseReason(type){
      this.countData = [];
      this.typeData = [];
        this.axios.request({
            method: "post", 
            url: '/dms/overview/caseReason',
            data: {
                projectId:getStore('thisProject').id,
                service:'liquidationAssist',
                companyName:getStore('thisProject').taxpayerName,
                projectName:getStore('thisProject').proName,
                caseType:type,
            },
        }).then((res) => {
            var reasonData = res.data.data;
            for(var i=0;i<reasonData.length;i++){
              this.countData.push(reasonData[i].count);
              this.typeData.push(reasonData[i].type);
            }
            this.renderZhuEcharts();

        })
    },
    
  },
};
</script>
<style lang="less" scoped>
.chart{
  width: 100%;
 
}
.caseReasonBox{
  float: left;
  width: 460px;
  height: 100%;
  overflow: hidden;
}
#caseReasonBox{
  width: 460px;
  height: 260px;
}
.zhuBox{
  width: 690px;
  height: 100%;
  float: left;
}
#zhuBox{
  width: 690px;
  height: 260px;
}
</style>
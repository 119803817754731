<template>
    <!--项目概览-->
    <div class="contain">
        <div class="main">
            <!-- 顶部 -->
            <div class="item_box">
                <div class="tb_left">
                    <div class="pro_card">
                        <p class="card_title">总建筑面积</p>
                        <p class="card_txt">{{basicInfo.buildingArea || '-'}}<span>m²</span></p>
                    </div>
                    <div class="pro_card">
                        <p class="card_title">总可售面积</p>
                        <p class="card_txt">{{basicInfo.availableArea || '-'}}<span>m²</span></p>
                    </div>
                    <div class="pro_card">
                        <p class="card_title">总用地面积</p>
                        <p class="card_txt">{{basicInfo.conversArea || '-'}}<span>m²</span></p>
                    </div>
                    <div class="pro_card">
                        <p class="card_title">平均单位售价</p>
                        <p class="card_txt">{{basicInfo.averagePrice || '-'}}<span>元/m²</span></p>
                    </div>
                    <div class="pro_card">
                        <p class="card_title">总收入</p>
                        <p class="card_txt">{{basicInfo.income || '-'}}<span>万元</span></p>
                    </div>
                    <div class="pro_card">
                        <p class="card_title">主要销售年度</p>
                        <p class="card_txt">{{basicInfo.annualSales || '-'}}</p>
                    </div>
                    <div class="pro_card">
                        <p class="card_title">主要建设年度</p>
                        <p class="card_txt">{{basicInfo.annualConstruction || '-'}}</p>
                    </div>
                    <div class="pro_card">
                        <p class="card_title">销售进度</p>
                        <p class="card_txt">{{basicInfo.progressSale || '-'}}%</p>
                    </div>
                    <div class="pro_card">
                        <p class="card_title">绿化率</p>
                        <p class="card_txt">{{basicInfo.greeningRate || '-'}}%</p>
                    </div><div class="pro_card">
                        <p class="card_title">容积率</p>
                        <p class="card_txt">{{basicInfo.plotRatio || '-'}}</p>
                    </div>

                </div>
                <div class="tb_map" @click="toProMap">
                    <div class="map_btn">点击查看项目实景</div>
                    <img src="../../assets/img/map.png" alt="">
                </div>
            </div>
            <!-- 对比 -->
            <div class="item_box">
                <div class="czdb_box">
                    <div class="item_title">申报成本与标准阈值差值对比<span class="unit">(单位：万元）</span> </div>
                    <costCompare v-if="costCompareShow" :costCompareData="costCompareData"></costCompare>
                </div>
                <div class="sbbfb_box">
                    <div class="item_title">申报成本百分比</div>
                    <costRatio v-if="costRatioShow" :costRatioData="costRatioData"></costRatio>
                </div>
                <div class="card_box">
                    <div class="card">
                        <p>同类项目成本区间</p>
                        <p class="bfb">{{costScopeData.minValue || '0'}}-{{costScopeData.maxValue || '0'}}<span>元/m²</span></p>
                        <p class="num">共<span>{{costScopeData.count || '0'}}</span> 个同类项目</p>
                    </div>
                    <div class="card">
                        <p>同类项目税负区间</p>
                        <p class="bfb">{{taxScopeData.minValue || '0'}}%-{{taxScopeData.maxValue || '0'}}%</p>
                        <p class="num">共<span>{{taxScopeData.count || '0'}}</span> 个同类项目</p>
                    </div>
                </div>
            </div>
            <!-- 建设方背景 -->
            <div class="item_box">
                <div class="build_box">
                    <div class="item_title">建设方背景 </div>
                    <buildTable :proInfo="buildData"></buildTable>
                </div>
            </div>
            <!-- 施工方信息 -->
            <div class="item_box">
                <div class="construction_box">
                    <div class="item_title">施工方信息 </div>
                    <div class="list_box">
                        <div class="const_item">
                            <div>
                                <p>全部施工单位数量</p>
                                <p><span>{{constructorInfos.constructionCount||'0'}}</span></p>
                            </div>
                            <div>
                                <p>全部施工单位合同总金额</p>
                                <p><span>{{constructorInfos.constructionAmount||'-'}}万元</span></p>
                            </div>
                        </div>
                        <div class="const_item">
                            <div>
                                <p>关联施工单位数量</p>
                                <p><span>{{constructorInfos.relatedConstructionCount||'0'}}</span></p>
                            </div>
                            <div>
                                <p>关联施工单位合同金额</p>
                                <p><span>{{constructorInfos.relatedConstructionAmount||'-'}}万元</span></p>
                            </div>
                        </div>
                        <div class="const_item">
                            <div>
                                <p>个人施工单位数量</p>
                                <p><span>{{constructorInfos.personalConstructionCount||'0'}}</span></p>
                            </div>
                            <div>
                                <p>个人施工单位合同金额</p>
                                <p><span>{{constructorInfos.personalConstructionAmount||'-'}}万元</span></p>
                            </div>
                        </div>
                        <div class="const_item">
                            <div>
                                <p>外市施工单位数量</p>
                                <p><span>{{constructorInfos.outterConstructionCount||'0'}}</span></p>
                            </div>
                            <div>
                                <p>外市施工单位合同金额</p>
                                <p><span>{{constructorInfos.outterConstructionAmount||'-'}}万元</span></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- 风险信息 -->
            <div class="item_box">
                <div class="fxxl_box">
                    <div class="item_title">风险信息 </div>
                    <caseReason v-if="caseReasonShow" :caseReasonData="caseReasonData"></caseReason>
                </div>
            </div>
        </div>

        
        
    </div>
    
</template>

<script>

import {getStore,setStore,getLocalStore} from "@/util/store";
import {mapMutations} from "vuex";
import buildTable from '@/views/projectOverview/buildTable.vue'
import costRatio from '@/views/projectOverview/costRatio.vue'
import costCompare from '@/views/projectOverview/costCompare.vue'
import caseReason from '@/views/projectOverview/caseReason.vue'

export default {
    name: "index",
    components: {
        buildTable,
        costRatio,
        costCompare,
        caseReason
    },

    data() {
        return {
            buildData:{},
            basicInfo:{},
            costRatioData:{},
            costScopeData:{},
            taxScopeData:{},
            constructorInfos:{},
            costCompareData:{},
            caseReasonData:{},
            costRatioShow: false,
            costCompareShow:false,
            caseReasonShow:false,
        }
    },
    created() {
    },  
   
    mounted() {
        //获取基本信息
        this.getBasicInfo();
        //申报成本与标准阈值差值对比
        this.getCostCompare();
        //申报成本百分比
        this.getCostRatio();
        //同类项目成本区间
        this.getCostScope();
        //同类项目税负区间
        this.getTaxScope();
        //建设方背景
        this.getCompanyInfos();
        //施工方信息
        this.getConstructorInfos();
        //风险信息
        this.getRiskInfo();


    },
 
    methods: {
        ...mapMutations(['addPath', "reducePath", "addMenuList", "reduceMenuList"]),
        //展现点击上报
        getReport(recordType,modelName,clickName){
            this.axios.request({
                method: "post", 
                url: '/dms/saveUserBehaviorRecord',
                 
                data: {
                    pageName:'industryAnalysis',
                    userName:getLocalStore('areainfo').userName,
                    recordType:recordType,
                    modelName:modelName,
                    clickName:clickName,
                    userId:getLocalStore('areainfo').id,
                    ipAddress:getLocalStore('areainfo').ipAddress,
                    userAddress:getLocalStore('areainfo').userAddress,
                    ubr: getStore('reportobj')||{},
                },
            }).then((res) => {
               setStore('reportobj',res.data.data)
            })
        },
        //基本信息
        getBasicInfo(){
            this.axios.request({
                method: "post", 
                url: '/dms/overview/basicInfo',
                 
                data: {
                    projectId:getStore('thisProject').id,
                    service:'liquidationAssist'
                },
            }).then((res) => {
                if(res.data.data){
                    this.basicInfo = res.data.data;
                }
               
            })
        },
        //申报成本与标准阈值差值对比
        getCostCompare(){
            this.axios.request({
                method: "post", 
                url: '/dms/overview/costCompare',
                 
                data: {
                    projectId:getStore('thisProject').id,
                    service:'liquidationAssist'
                },
            }).then((res) => {
                if(res.data.data){
                    this.costCompareData = res.data.data;
                    this.costCompareShow = true;
                }
                
            //    this.costRatioData = res.data.data;
            })
        },
        //申报成本百分比
        getCostRatio(){
            this.axios.request({
                method: "post", 
                url: '/dms/overview/costRatio',
                 
                data: {
                    projectId:getStore('thisProject').id,
                    service:'liquidationAssist'
                },
            }).then((res) => {
                if(res.data.data){
                    this.costRatioData = res.data.data;
                    this.costRatioShow = true;
                }
               
               
            })
        },
        //同类项目成本区间
        getCostScope(){
            this.axios.request({
                method: "post", 
                url: '/dms/overview/costScope',
                 
                data: {
                    projectId:getStore('thisProject').id,
                    service:'liquidationAssist'
                },
            }).then((res) => {
                if(res.data.data){
                    this.costScopeData = res.data.data;
                }
               
            })
        },
        //同类项目税负区间
        getTaxScope(){
            this.axios.request({
                method: "post", 
                url: '/dms/overview/taxScope',
                 
                data: {
                    projectId:getStore('thisProject').id,
                    service:'liquidationAssist'
                },
            }).then((res) => {
                if(res.data.data){
                    this.taxScopeData = res.data.data;
                }
               
            })
        },
        //建设方背景
        getCompanyInfos(){
            this.axios.request({
                method: "post", 
                url: '/dms/overview/companyInfos',
                 
                data: {
                    projectId:getStore('thisProject').id,
                    // projectId:'90006547',
                    companyName:getStore('thisProject').taxpayerName,
                    taxpayerCode:getStore('thisProject').taxpayerCode,
                    service:'liquidationAssist'
                },
            }).then((res) => {
                if(res.data.data){
                    this.buildData = res.data.data;
                }
                
                // this.buildData = {
                //         "id": null,
                //         "projectId": null,
                //         "projectSource": null,
                //         "keyNo": null,
                //         "companyName": "河北盈驰房地产开发有限公司",
                //         "registerDate": "2008-03-21",
                //         "phoneNumber": "0311-87045555",
                //         "phoneNumberKey": "769b7a1d27646e45",
                //         "phoneNumberCount": 4,
                //         "phoneNumberMoreList": [
                //             {
                //                 "phoneNumberAddress": "0311-87702555",
                //                 "count": 2,
                //                 "key": "887ddacdd8dd6c5b"
                //             },
                //             {
                //                 "phoneNumberAddress": "15227178888",
                //                 "count": 3,
                //                 "key": "66f2c5741804b311"
                //             }
                //         ],
                //         "shareHolderNumber": 2,
                //         "legalShareHolderNumber": 0,
                //         "address": "河北省石家庄市新华区联盟路与北新街交叉口西北角办公楼2楼201室",
                //         "addressKey": null,
                //         "addressCount": 0,
                //         "projectNumber": 0,
                //         "projectArea": 0,
                //         "shareHolderIndustrialCount": 0,
                //         "investorProjectNumber": 0,
                //         "landNumber": 0,
                //         "investorLandNumber": 0,
                //         "landArea": null,
                //         "landCost": 0,
                //         "isShareHolderChange": false
                //     };

            })
        },
        //施工方信息
        getConstructorInfos(){
            this.axios.request({
                method: "post", 
                url: '/dms/overview/constructorInfos',
                 
                data: {
                    projectId:getStore('thisProject').id,
                    service:'liquidationAssist'
                },
            }).then((res) => {
                if(res.data.data){
                    this.constructorInfos = res.data.data;
                }
                
            })
        },
        //风险信息
        getRiskInfo(){
            this.axios.request({
                method: "post", 
                url: '/dms/overview/riskInfo',
                 
                data: {
                    projectId:getStore('thisProject').id,
                    service:'liquidationAssist',
                    companyName:getStore('thisProject').taxpayerName,
                    projectName:getStore('thisProject').proName,
                },
            }).then((res) => {
                if(res.data.data){
                    this.caseReasonData = res.data.data;
                    this.caseReasonShow = true;
                }
                
            })
        },
        toProMap(){
            this.addPath("项目地图");
            this.addMenuList("/proMap");
            setStore("currentMenu", "/proMap");
            this.$router.push({path: '/proMap'});
        },
       
        
    }
}
</script>

<style scoped lang="less">
::-webkit-scrollbar {
    width: 8px;
    height: 10px;
}
::-webkit-scrollbar-thumb {
    background-color: #D8D8D8;
    border-radius: 4px;
}
.contain{
    height: 100%;
    overflow-y: scroll;
    .main{
        width: 1200px;
        margin: 0 auto;
    }
    .item_box{
        width: 100%;
        height: auto;
        margin-bottom: 24px;
        overflow: hidden;
        padding-bottom: 6px;
        .tb_left{
            width: 880px;
            height: 216px;
            overflow: hidden;
            float: left;
            .pro_card{
                width: 165px;
                height: 101px;
                background: #FFFFFF;
                box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
                border-radius: 4px;
                box-sizing: border-box;
                float: left;
                margin-right: 8px;
                margin-bottom: 10px;
                padding: 19px 20px;
                .card_title{
                    font-size: 14px;
                    font-weight: 500;
                    color: #999999;
                    margin-bottom: 14px;
                }
                .card_txt{
                    font-size: 18px;
                    font-weight: 600;
                    color: #185BCE;
                    span{
                        font-size: 12px;
                    }
                }
            }
            
        }
        .tb_map{
            float: right;
            width: 320px;
            height: 212px;
            position: relative;
            cursor: pointer;
            img{
                width: 100%;
                height: 100%;
            }
            .map_btn{
                width: 128px;
                height: 29px;
                background: rgba(0, 0, 0, 0.3);
                border-radius: 0px 0px 0px 10px;
                line-height: 29px;
                text-align: center;
                color: #FFFFFF;
                position: absolute;
                right: 0;
                top: 0;
            }
        }
        .czdb_box{
            width: 531px;
            height: 293px;
            background: #FFFFFF;
            box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
            border-radius: 2px;
            float: left;
            margin-right: 25px;
            padding: 16px 24px;
            box-sizing: border-box;

        }
        .sbbfb_box{
            width: 381px;
            height: 293px;
            background: #FFFFFF;
            box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
            border-radius: 2px;
            float: left;
            margin-right: 23px;
            box-sizing: border-box;
            .item_title{
                margin-left: 24px;
                margin-top: 16px;
            }
        }
        .card_box{
            width: 239px;
            height: 293px;
            float: left;
            .card{
                width: 239px;
                height: 135px;
                background: #FFFFFF;
                box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
                border-radius: 2px;
                margin-bottom: 24px;
                padding-top: 16px;
                box-sizing: border-box;
                p{
                    text-align: center;
                    margin-bottom: 16px;
                    font-weight: 600;
                    color: #333333;
                    font-size: 14px;
                }
                .bfb{
                    font-size: 18px;
                    font-weight: 600;
                    color: #185BCE;
                    span{
                        font-size: 12px;
                    }
                }
                .num{
                    font-weight: 400;
                    color: #999999;
                    span{
                        color: #185BCE;
                    }
                }
            }
        }
        .build_box{
            width: 1200px;
            height: 376px;
            background: #FFFFFF;
            border-radius: 2px;
            padding: 16px 24px;
            box-sizing: border-box;
        }
        .construction_box{
            width: 1200px;
            height: 202px;
            background: #FFFFFF;
            border-radius: 2px;
            padding: 16px 24px;
            box-sizing: border-box;
            .list_box{
                width: 100%;
                overflow: hidden;
                .const_item{
                    width: 255px;
                    border-right: 1px solid #D8D8D8;
                    float: left;
                    margin-right: 30px;
                    padding-left: 40px;
                    box-sizing: border-box;
                    div{
                        margin-bottom: 8px;
                    }
                    p{
                        font-size: 14px;
                        font-weight: 600;
                        color: #999999;
                        margin-bottom: 8px;
                        span{
                            font-size: 18px;
                            font-weight: 600;
                            color: #185BCE;
                            margin-left: 3px;
                        }
                    }
                }
                .const_item:last-child{
                    border: none;
                }
            }
        }
        .fxxl_box{
            width: 1200px;
            height: 342px;
            background: #FFFFFF;
            border-radius: 2px;
            padding: 16px 24px;
            box-sizing: border-box;
        }
        .item_title{
            border-left: 6px solid #185BCE;
            height: 22px;
            padding-left:8px;
            font-size: 16px;
            font-weight: 600;
            color: #333333;
            line-height: 24px;
            margin-bottom: 24px;
        }
        .unit{
            font-size: 12px;
            font-weight: 400;
            color: #8C8C8C;
            line-height: 17px;
        }
    }
}
.map_box{
    /deep/.el-dialog{
        height: 90%;
    }
    /deep/.el-dialog__body{
        height: 100%;
        padding: 0;
    }
    /deep/.el-dialog__header{
        padding: 0;
    }
}

</style>
<!--历史建设项目详情 xwh 20210910-->
<template>
    <div class="">
        <div class="pro-info-box">
            <table>
                <colgroup>
                    <col width="200"></col>
                    <col width="300"></col>
                    <col width="200"></col>
                    <col width="300"></col>
                </colgroup>
                <tr>
                    <th>项目名称</th>
                    <td>{{ proInfo.projectName }}</td>
                    <th>电子监管号</th>
                    <td>{{ proInfo.supervisionNumber }}</td>
                </tr>
                <tr>
                    <th>行政区</th>
                    <td>{{ proInfo.cityName }}</td>
                    <th>项目位置</th>
                    <td>{{ proInfo.projectAddress }}</td>
                </tr>
                <tr>
                    <th>面积（公顷）</th>
                    <td>{{ utils.dataFormat(proInfo.areaSize) }}</td>
                    <th>土地来源</th>
                    <td>{{ proInfo.landSource }}</td>
                </tr>
                <tr>
                    <th>土地用途</th>
                    <td>{{ proInfo.landUsage }}</td>
                    <th>供地方式</th>
                    <td>{{ proInfo.landSupplyMethod }}</td>
                </tr>
                <tr>
                    <th>土地使用年限</th>
                    <td>{{ proInfo.landUsagePeriod }}</td>
                    <th>行业分类</th>
                    <td>{{ proInfo.industryCategory }}</td>
                </tr>
                <tr>
                    <th>土地级别</th>
                    <td>{{ proInfo.landLevel }}</td>
                    <th>成交价格(万元)</th>
                    <td>{{ proInfo.salePrice }}</td>
                </tr>
                <tr>
                    <th>容积率下限</th>
                    <td>{{ proInfo.plotRatioDown }}</td>
                    <th>容积率上限</th>
                    <td>{{ proInfo.plotRatioUp }}</td>
                </tr>
                <tr>
                    <th>土地使用权</th>
                    <td>{{ proInfo.landHolder}}</td>
                    <th>约定交地时间</th>
                    <td>{{ proInfo.arrangeDeliveryDime }}</td>
                </tr>
                <tr>
                    <th>约定开工时间</th>
                    <td>{{ proInfo.arrangeStartTime }}</td>
                    <th>约定竣工时间</th>
                    <td>{{ proInfo.arrangeEndTime }}</td>
                </tr>
                <tr>
                    <th>实际开工时间</th>
                    <td>{{ proInfo.realStartTime }}</td>
                    <th>实际竣工时间</th>
                    <td>{{ proInfo.realEndTime }}</td>
                </tr>
                <tr>
                    <th>批准单位</th>
                    <td>{{ proInfo.authority }}</td>
                    <th>合同签订日期</th>
                    <td>{{ proInfo.contractSigningDate }}</td>
                </tr>
            </table>
        </div>
    </div>
</template>

<script>

export default {
    props: {
        'proInfo': {},
    },
    data() {
        return {

        }
    },
    created() {
        //console.log(this.proInfo)
    },
    mounted() {

    },
    methods: {

    }
}
</script>

<style scoped lang="less">
.pro-info-box {
    table {
        width: 100%;
        border-collapse: collapse;
    }

    th, td {
        padding: 10px;
        border: 1px solid #EBEBEB;
        white-space: nowrap;
        font-weight: 500;
        text-align: left;
    }

    th {
        background: #F6F6F6;
    }

    td {
        height: 1px;
        white-space: normal;

        #mapContainer {
            height: 100%;
            background: #ccc;
        }
    }

}
</style>
<!--中标业绩 xwh 20210913-->
<template>
<div class="base-container">
    <!-- <div class="main-wrapper"> -->
        <div id="land-transaction">
            <div class="search-box">
                <div class="module-title">土地交易信息</div>
                <el-form :inline="true" class="demo-form-inline" size="mini">
                    <el-form-item label="项目名称">
                        <el-input v-model="projectName" placeholder="请输入项目名称"></el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="getTableList()">查询</el-button>
                    </el-form-item>
                </el-form>
            </div>
            <el-table ref="table" :data="tableData" border stripe height="400" :summary-method="getSummaries" :header-cell-style="{background:'#F6F6F6',color:'#333333'}"
                    v-loading="loading" @sort-change="sortChange">
                <el-table-column sortable='custom' type="index" label="序号" width="80" align="center"></el-table-column>
                <el-table-column prop="projectName" label="项目名称" :show-overflow-tooltip="true"></el-table-column>
                <el-table-column sortable='custom' prop="contractSigningDate" label="合同日期"  align="right"></el-table-column>
                <el-table-column sortable='custom' prop="salePrice" label="成交价格(万元)" align="right">
                    <template slot-scope="scope">
                        <span>{{ utils.dataFormat(scope.row.salePrice) }}</span>
                    </template>
                </el-table-column>
                <el-table-column sortable='custom' prop="landUsage" label="土地用途" ></el-table-column>
                <el-table-column sortable='custom' prop="province" label="项目属地">
                    <template slot-scope="scope">
                        <span>{{ scope.row.province }}{{ scope.row.city }}{{ scope.row.country }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="tendereeAndTel"  label="招标人及联系方式"></el-table-column>
                <el-table-column label="操作" width="80" align="center">
                    <template slot-scope="scope">
                        <el-button size="mini" type="text" @click="detail(scope.$index, scope.row)">详情</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <div class="page-box">
                <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    background
                    layout="prev,pager,next,total,sizes,jumper"
                    :page-sizes="[10, 50, 100]"
                    :total=total
                    :current-page=pageNum>
                </el-pagination>
            </div>
            <el-dialog :modal-append-to-body="true" :append-to-body="true"  title="土地交易详情" :visible.sync="showDetail" @close="closeDialog" width="90%">
                <detail :proInfo="proInfo"></detail>
            </el-dialog>
        </div>
    <!-- </div> -->
</div>
    
</template>

<script>
import {getStore, setStore,getLocalStore} from '@/util/store'
import detail from "./landTransactionDetail";
export default {
    components: {
        detail
    },
    data() {
        return {
            tableData: [],
            loading: true,
            total: 0,
            dataTotal:['合计','','',22222,'',''],
            proInfo: {},
            showDetail:false,
            projectName: '',
            pageSize: 10,
            pageNum: 1,
            field: '',
            order: '',
        }
    },
    created() {
        this.getTableList();
    },
    mounted() {

    },
    methods: {
        search(){
            this.pageNum = 1;
            this.getReport(2,'landTransaction','searchBtn')
            this.getTableList();
        },
        //展现点击上报
        getReport(recordType,modelName,clickName){
            this.axios.request({
                method: "post", 
                url: '/dms/saveUserBehaviorRecord',
                data: {
                    pageName:'building',
                    userName:getLocalStore('areainfo').userName,
                    recordType:recordType,
                    modelName:modelName,
                    clickName:clickName,
                    userId:getLocalStore('areainfo').id,
                    ipAddress:getLocalStore('areainfo').ipAddress,
                    userAddress:getLocalStore('areainfo').userAddress,
                    ubr: getStore('reportobj')||{},
                },
            }).then((res) => {
               setStore('reportobj',res.data.data)
            })
        },
        //查询talbe数据
        getTableList() {
            this.axios({
                method: "post", url: '/dms/searchLandMarket',
                data: {
                    landHolder: getStore('thisProject').taxpayerName,
                    pageNum: this.pageNum,
                    pageSize: this.pageSize,
                    projectName:this.projectName,
                    field: this.field,
                    order: this.order,
                },
            }).then((res) => {
                //this.loading = false;
                this.tableData = res.data.data.records;
                this.total = res.data.data.total;
                //this.total = 200
                this.loading = false;
            }).catch((error) => {
                console.log(error)
            })
        },
        //table合计
        getSummaries() {
            //解决合计被遮挡问题
            this.$nextTick(() => {
                this.$refs.table.doLayout();
            });
            return this.dataTotal;;
        },
        //更改每页显示条数
        handleSizeChange(val) {
            this.pageSize = val;
            this.pageNum = 1;
            this.getTableList();
        },
        //更改页码
        handleCurrentChange(val) {
            this.pageNum = val;
            this.getReport(2,'landTransaction',val)
            this.getTableList();
        },
        //查看详情
        detail(index, row) {
            this.proInfo = row;
            this.getReport(2,'landTransaction','detailBtn')
            this.showDetail = true;
        },
        closeDialog(){
            this.getReport(2,'landTransaction','detailClose')
        },
        sortChange({ prop, order }) {
            this.pageNum = 1;
            this.getReport(2,'landTransaction',prop+'Sort')
            this.field = prop;
            if(order == 'ascending'){
                this.order = 'asc'
            }else if(order == 'descending'){
                this.order = 'desc'
            }else{
                this.order = 'asc'
            }
            this.getTableList()
        },
    }
}
</script>

<style scoped lang="less">
    .search-box {
        display: flex;
        justify-content: space-between;
    }
    .module-title {
        font-size: 16px;
        font-weight: bold;
    }

</style>
<template>
    <div class="">
        <div class="pro-info-box">
            <table>
                <tr>
                    <th>企业名称</th>
                    <td>{{ proInfo.companyName!=null ? proInfo.companyName:'-' }}</td>
                    <th>成立时间</th>
                    <td>{{ proInfo.registerDate!=null ? proInfo.registerDate:'-' }}</td>
                </tr>
                <tr>
                    <th>联系电话</th>
                    <td class="fen">
                        <span>{{ proInfo.phoneNumber!=null ? proInfo.phoneNumber:'-' }}</span>
                        <el-popover
                            v-if="proInfo.phoneNumberMoreList && proInfo.phoneNumberMoreList.length"
                            placement="bottom"
                            width="280"
                            trigger="click">
                            <p class="popover" v-for="(item, index) in proInfo.phoneNumberMoreList" :key="index">
                                <span>{{item.phoneNumberAddress}}</span>
                                <span @click="getSamePhoneOrAddress(item.key,'phone')" class="more">同联系电话企业 {{item.count}}</span>
                            </p>
                           
                            <el-button slot="reference" class="btn_more">更多</el-button>
                        </el-popover>
                        <span v-if="proInfo.phoneNumberCount>0" @click="getSamePhoneOrAddress(proInfo.phoneNumberKey,'phone')" class="more">同联系电话企业 {{proInfo.phoneNumberCount}}</span>

                    </td>
                    <th>自然人股东数量</th>
                    <td class="blue">{{ proInfo.shareHolderNumber!=null ? proInfo.shareHolderNumber:'-' }}</td>
                </tr>
                <tr>
                    <th>企业地址</th>
                    <td class="fen">
                        {{ proInfo.address!=null ? proInfo.address:'-' }}
                        <span class="more" v-if="proInfo.addressCount>0" @click="getSamePhoneOrAddress(proInfo.addressKey,'address')">同地址企业 {{proInfo.addressCount}}</span>
                    </td>
                    <th>法人股东数量</th>
                    <td class="blue">{{ proInfo.legalShareHolderNumber!=null ? proInfo.legalShareHolderNumber:'-' }}</td>
                </tr>
                <tr>
                    <th>累计开发项目数量</th>
                    <td class="yellow">{{ proInfo.projectNumber!=null ? proInfo.projectNumber:'-' }} <span class="more" @click="getMap">查看详情</span></td>
                    <th>股东投资行业数量</th>
                    <td class="blue">{{ proInfo.shareHolderIndustrialCount!=null ? proInfo.shareHolderIndustrialCount:'-' }}</td>
                </tr>
                <tr>
                    <th>累计开发项目面积</th>
                    <td class="yellow">{{ proInfo.projectArea!=null ? proInfo.projectArea:'0' }}m²</td>
                    <th>投资人历史建设项目数量</th>
                    <td class="blue">{{ proInfo.investorProjectNumber!=null ? proInfo.investorProjectNumber:'-' }}</td>
                </tr>
                <tr>
                    <th>累计获取土地数量</th>
                    <td class="yellow">{{ proInfo.landNumber!=null ? proInfo.landNumber:'-' }} <span v-if="proInfo.landNumber>0" class="more" @click="getLand">查看详情</span></td>
                    <th>投资人历史拿地数量</th>
                    <td class="blue">{{ proInfo.investorLandNumber!=null ? proInfo.investorLandNumber:'-' }}</td>
                </tr>
                <tr>
                    <th>累计获取土地面积</th>
                    <td class="yellow">{{ proInfo.landArea!=null ? proInfo.landArea:'0' }}m²</td>
                    <th>投资人/股权变动情况</th>
                    <td class="blue">{{ proInfo.isShareHolderChange? '有':'无'}} <span class="more" v-if="proInfo.isShareHolderChange" @click="equityChangeInfo">变更记录</span></td>
                </tr>
                <tr>
                    <th>累积土地交易金额</th>
                    <td class="right_td yellow">{{ proInfo.landCost!=null ? proInfo.landCost:'0' }}万元</td>
                    <td class="null_td yellow"></td>
                    <td class="null_td yellow"></td>
                </tr>
             
            </table>
        </div>
         <el-dialog :modal-append-to-body="false" :visible.sync="showDetail" @close="closeDialog" width="90%">
             <landTransaction></landTransaction>
        </el-dialog>
        <el-dialog :title="proTitle" :visible.sync="showSame" @close="closeDialog" width="90%">
            <el-table ref="table" :data="sameData" border stripe :header-cell-style="{background:'#F6F6F6',color:'#333333'}">
                <el-table-column type="index" label="序号" align="center" width="80" ></el-table-column>
                <el-table-column prop="companyName" label="企业名称"  align="right"></el-table-column>
                <el-table-column prop="owner" label="法定代表人"  align="right"></el-table-column>
                <el-table-column prop="registrationCapital" label="注册资本（万元）"  align="right"></el-table-column>
                <el-table-column label="成立日期"  align="right">
                    <template slot-scope="scope">
                        <span>{{ utils.formatDate(scope.row.registrationDate) }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="registrationStatus" label="登记状态"  align="right"></el-table-column>
            </el-table>
        </el-dialog>
        <el-dialog title="变更记录" :visible.sync="showChange" @close="closeDialog" width="90%">
            <el-table class="change" ref="table" :data="changeData" border stripe :header-cell-style="{background:'#F6F6F6',color:'#333333'}">
                <el-table-column type="index" label="序号" align="center" width="80" ></el-table-column>
                <el-table-column label="变更日期"  align="right" width="160">
                     <template slot-scope="scope">
                        <span>{{ utils.formatDate(scope.row.changedate) }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="type" label="变更项目"  align="right" width="200"></el-table-column>
                <el-table-column label="变更前" align="right">
                    <template slot-scope="scope">
                        <el-popover
                            placement="bottom-start"
                            width="358"
                            trigger="hover"
                            >
                            <p v-html="scope.row.infoBefore"></p>
                            <el-button slot="reference" v-html="scope.row.infoBefore"></el-button>
                        </el-popover>
                    </template>
                </el-table-column>
                <el-table-column label="变更后" align="right">
                    <template slot-scope="scope">
                        <el-popover
                            placement="bottom-start"
                            width="358"
                            trigger="hover"
                            >
                            <p v-html="scope.row.infoAfter"></p>
                            <el-button slot="reference" v-html="scope.row.infoAfter"></el-button>
                        </el-popover>
                    </template>
                </el-table-column>
            </el-table>
        </el-dialog>
    </div>
</template>

<script>
import {getStore,setStore} from "@/util/store";
import {mapMutations} from "vuex";
import landTransaction from "@/views/analysisDetails/landTransaction/landTransaction";
export default {
    components: {
        landTransaction
    },
    props: {
        'proInfo': {},
    },
    watch:{
        proInfo:{
            handler: function(){
                
            },
            deep: true
        }
    },
    data() {
        return {
            showDetail: false,
            showSame: false,
            showChange: false,
            pageNum:1,
            pageSize:10,
            sameData:[],
            changeData:[],
            proTitle:'同联系电话企业'
        }
    },
    created() {
    },
    mounted() {

    },
    methods: {
        ...mapMutations(['addPath', "reducePath", "addMenuList", "reduceMenuList"]),
        //获取更多企业列表
        getSamePhoneOrAddress(key,type){
            this.axios.request({
                method: "post", 
                url: '/dms/overview/samePhoneOrAddress',
                 
                data: {
                    taxpayerCode:getStore('thisProject').taxpayerCode,
                    // taxpayerCode:'91130100673218012Y',
                    key:key,
                    // key:'887ddacdd8dd6c5b',
                    pageNum:this.pageNum,
                    pageSize:this.pageSize,
                },
            }).then((res) => {
                console.log(res);
                this.sameData = res.data.data.data.records;
                if(type == 'phone'){
                    this.proTitle = '同联系电话企业'
                }else{
                    this.proTitle = '同地址企业'
                }
                this.showSame = true;
            })
        },
        equityChangeInfo(){
            this.axios.request({
                method: "post", 
                url: '/dms/overview/equityChangeInfo',
                 
                data: {
                    taxpayerCode:getStore('thisProject').taxpayerCode,
                    // taxpayerCode:'91371122775292213X',
                    pageNum:this.pageNum,
                    pageSize:this.pageSize,
                },
            }).then((res) => {
                console.log(res);
                this.changeData = res.data.data.data.records;
                this.showChange = true;
            })
        },
        //打开土地详情
        getLand(){
            this.showDetail = true;
        },
        //打开累计开发 项目数量地图
        getMap(){
            this.addPath("项目地图");
            this.addMenuList("/moreMap");
            setStore("currentMenu", "/moreMap");
            this.$router.push({path: '/moreMap'});
        },
        //关闭
        closeDialog(){
            this.showDetail = false;
            this.showSame = false;
            this.showChange = false;
        }


    }
}
</script>

<style scoped lang="less">
.pro-info-box {
    table {
        width: 100%;
        border-collapse: collapse;
    }

    th, td {
        padding: 8px;
        border: 1px solid #EBEBEB;
        white-space: nowrap;
        font-weight: 500;
        text-align: left;
    }

    th {
        background: #F6F6F6;
        padding-left: 11px;
        width: 188px;
    }

    td {
        height: 1px;
        white-space: normal;
    }
    .right_td{
        border-right: none;
    }
    .null_td{
        border: none;
        border-bottom: 1px solid #EBEBEB;
    }
    .null_td:last-child{
        border-right: 1px solid #EBEBEB;
    }
    .fen{
        background: #FDF9F9;
    }
    .yellow{
        background: #FFFEF9;
    }
    .blue{
        background: #F9FDFF;
    }
    
    .el-button{
        border: none;
        padding: 0;
        background: none;
        margin-left: 24px;
        color: #185BCE;
    }
}
.popover{
    padding-left: 4px;
}
.more{
    color: #185BCE;
    cursor: pointer;
    margin-left: 24px;
}
.el-button{
    border: none;
    padding: 0;
    background: none;
}
/deep/.el-table{
    .cell{
        span{
            max-width: 520px;
        }
    }
    em{
        color: red !important;
    }
}

</style>
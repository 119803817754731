<template>
  <div class="chart">
    <div id="chartbox"></div>
  </div>
</template>
<script>
export default {
  props: {
    costRatioData: {},
  },
  data() {
    return {
      myChart:{}
    };
  },
  watch:{
    costRatioData:{
      handler: function(){
          this.renderEcharts();
      },
      deep: true
    }
  },
  created(){
    setTimeout(() => {
      this.renderEcharts();
    }, 500);
    
  },
  
  methods: {
    renderEcharts(){
      this.myChart = this.$echarts.init(document.getElementById('chartbox'));
      let option;
      option = {
          title: {
              text:'成本总金额',//主标题文本
              subtext:parseInt(this.costRatioData.totalCost/10000)+'万元',//副标题文本
              left:'130px',
              top:'40%',
              textAlign:'center',
              textStyle:{
                fontSize: 14,
                color:'#999999',
              },
              subtextStyle:{
                  fontFamily : "微软雅黑",
                  fontSize: 16,
                  color:'#555555',
                  fontWeight: '600',
              }
          },
          tooltip: {
              trigger: 'item',
              formatter: '{a} <br/>{b}: {c} ({d}%)'
          },
          legend: {
              orient: 'vertical',
              icon: "circle",
              right: 20,
              bottom:20,
              data: ['取得土地使用权所支付的金额','前期工程费', '建筑安装工程费', '基础设施费', '公共配套设施费', '开发间接费'],
              formatter: function (params) {
                //超过7个字符就换行展示
                var newParamsName = "";// 最终拼接成的字符串
                var paramsNameNumber = params.length;// 实际标签的个数
                var provideNumber = 7;// 每行能显示的字的个数
                var rowNumber = Math.ceil(paramsNameNumber / provideNumber);// 换行的话，需要显示几行，向上取整
                // 条件等同于rowNumber>1
                if (paramsNameNumber > provideNumber) {
                    for (var p = 0; p < rowNumber; p++) {
                        var tempStr = "";// 表示每一次截取的字符串
                        var start = p * provideNumber;// 开始截取的位置
                        var end = start + provideNumber;// 结束截取的位置
                        // 此处特殊处理最后一行的索引值
                        if (p == rowNumber - 1) {
                            // 最后一次不换行
                            tempStr = params.substring(start, paramsNameNumber);
                        } else {
                            // 每一次拼接字符串并换行
                            tempStr = params.substring(start, end) + "\n";
                        }
                        newParamsName += tempStr;// 最终拼成的字符串
                    }
                } else {
                    // 将旧标签的值赋给新标签
                    newParamsName = params;
                }
                //将最终的字符串返回
                return newParamsName
              }
          },
          series: [
              {
                  name: '申报成本百分比',
                  type: 'pie',
                  radius: ['55%', '90%'],
                  avoidLabelOverlap: false,
                  center: ['135px', '50%'],
                  label: {
                    normal:{
                        show:true,
                        position:'inner', //标签的位置
                        textStyle : {
                          color:'#fff',
                          fontWeight : 300 ,
                          fontSize : 12    //文字的字体大小
                        },
                        formatter:'{d}%'
                    }
                  },
               
                  labelLine: {
                      show: false
                  },
                  data: [
                      {value: this.costRatioData.landCost, name: '取得土地使用权所支付的金额'},
                      {value: this.costRatioData.preConstructionCost, name: '前期工程费'},
                      {value: this.costRatioData.civilErectionCost, name: '建筑安装工程费'},
                      {value: this.costRatioData.infrastructureFee, name: '基础设施费'},
                      {value: this.costRatioData.publicFacilitiesFee || 1, name: '公共配套设施费'},
                      {value: this.costRatioData.developmentOverhead, name: '开发间接费'}
                  ]
              }
          ]
      };


      this.myChart.setOption(option,true);
    },

    
  },
};
</script>
<style lang="less" scoped>
.chart{
  width: 381px;
 
}
#chartbox{
  width: 381px;
  height: 230px;
}
</style>